export default{
    namespaced: true,
    state: () => ({
      appointments: [],
      appointment: null
    }),
    getters: {
        appointments: function(state) {
            return state.appointments;
        },
        appointment: function(state) {
            return state.appointment;
        }
    },
    mutations: {
        setAppointments: function(state, payload) {
            state.appointments = payload;
        },
        setAppointment: function(state, payload) {
            state.appointment = payload;
        }
    },
    actions: {
        getAppointments: async function(context, payload) {
            await axios.post("get_appointments", payload, {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    "Content-Type": "application/json",
                }
            }).then(res => {
                context.commit('setAppointments', res.data);
            }).catch(error => console.log(error.message));
        },
        getAppointment: async function(context, id) {
            await axios.get("get_appointment/"+id,
                {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    "Content-Type": "application/json",
                }
            }).then(res => {
                context.commit('setAppointment', res.data);
            }).catch(error => console.log(error.message));
        },
        save: async function(context, payload) {
            let success = false;
            await axios.post("save_appointment", payload, {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    "Content-Type": "application/json",
                }
            }).then(res => {
                console.log(res.data);
                success = true;
            }).catch(error => console.log(error.message));
            return success;
        },
        updateAppointment: async function(context, id,payload) {
            let success = false;
            await axios.post("update_appointment"+id, payload, {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    "Content-Type": "application/json",
                }
            }).then(res => {
                console.log(res.data);
                success = true;
            }).catch(error => console.log(error.message));
            return success;
        },
    }
}