import { createRouter, createWebHistory } from 'vue-router'

async function guard(to, from, next){
  let token = localStorage.getItem('_accessToken');
  await axios.get('check-auth', {
    headers: {
      Authorization: token ? token : null
    }
  }).then(res => null)
    .catch(e => {
      if(e.response.statusText == 'Unauthorized'){
        localStorage.removeItem('_accessToken')
        localStorage.removeItem('_user');
        token = null;
      }
    })

  if(token === null || token === 'null' || token == undefined || token == '') {
    console.log(token)
    next({ name: 'Login' })
  } 
  else next()
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/print-prescription/:id',
    name: 'PrintPrescription',
    beforeEnter: guard,
    component: () => import(/* webpackChunkName: "Prescription" */ '../views/Prescription.vue')
  },
  {
    path: '/',
    beforeEnter: guard,
    component: () => import(/* webpackChunkName: "layout" */ '../layouts/Default.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "Users" */ '../views/Users.vue')
      },
      {
        path: '/appointments',
        name: 'Appointments',
        component: () => import(/* webpackChunkName: "Appointments" */ '../views/Appointments.vue')
      },
      {
        path: '/single-appointment/:id',
        name: 'SingleAppointment',
        component: () => import(/* webpackChunkName: "SingleAppointment" */ '../views/SingleAppointment.vue')
      },
      {
        path: '/update-appointment/:id',
        name: 'UpdateAppointment',
        component: () => import(/* webpackChunkName: "SingleAppointment" */ '../views/UpdateAppointment.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
