export default {
    namespaced: true,
    state: () => ({
        prescriptions: [],
        prescription: null
    }),

    getters: {
        prescriptions: function(state) {
            return state.prescriptions;
        },
        prescription: function(state) {
            return state.prescription;
        }
    },

    mutations: {
        setPrescriptions: function(state, payload) {
            state.prescriptions = payload;
        },
        setPrescription: function(state, payload) {
            state.prescription = payload;
        }
    },
    actions: {
        save: async function(context, payload) {
           let success = false;
            await axios.post("save_prescription", payload, {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    "Content-Type": "application/json"
                }
            }).then(response => {
                let r = response.data;
                success = true;
            }).catch(error => {
                success = false;
            });

            return success;
        },
        getPrescriptions: async function(context, payload) {
            await axios.get("get_prescriptions/"+payload, {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    "Content-Type": "application/json"
                }
            }).then(res => {
                context.commit('setPrescriptions', res.data.prescriptions);
            }).catch(error => error.message);
        }
    }

}