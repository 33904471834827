export default {
    namespaced: true,

    state: () => ({
        userId: null,
        user: null,
        token: null,
        users: [],
        dashboard: null
    }),
    getters: {
        jwt: (state) => {
            if(state.token)
                return state.token;
            else
                return localStorage.getItem('_accessToken');
        },
        
        users: (state) => {
            return state.users;
        },

        dashboard: (state) => {
            return state.dashboard;
        }
    }, 
    mutations: {
        setUser: (state,payload) => {
            state.user = payload;
        },
        setUserId: (state,payload) => {
            state.userId = payload;
        },
        setToken: (state,payload) => {
            state.token = payload;
        },
        setUsers: (state, payload) => {
            state.users = payload;
        },
        setDashboard: (state, payload) => {
            state.dashboard = payload;
        }
    },
    actions: {
        getDashboard: async function(context) {
            await axios.get('dashboard', {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    "Content-Type": "application/json",
                }
            }).then(res => {
                // console.log(res.data);
                context.commit('setDashboard', res.data);
            })
        },
        getUsers: async function(context) {
            await axios.get('get_users', {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    "Content-Type": "application/json",
                }
            }).then(res => {
                context.commit('setUsers', res.data.users);
            })
        },

        login: async function(context, payload) {
            let success = false;
            await axios.post("login", payload).then(res => {
                let r = res.data.response;
                context.commit('setUser', r.user);
                context.commit('setUserId', r.user.id);
                context.commit('setToken', r.token);
                localStorage.setItem('_accessToken', r.token);
                localStorage.setItem('_user', JSON.stringify(r.user));
                success = true;
            }).catch(error => console.log(error.message));
            return success;
        },
        logout: async function(context) {
            let success = false;
            await axios.get("logout", {
                headers: {
                    Authorization: this.getters['auth/jwt'],
                    "Content-Type": "application/json",
                }
            }).then(res => {
                let r = res.data;
                context.commit('setUser', null);
                context.commit('setUserId', null);
                context.commit('setToken', null);
                localStorage.removeItem('_accessToken');
                localStorage.removeItem('_user');
                success = true;
            }).catch(error => console.log(error.message))
            return success;
        }
    }
}