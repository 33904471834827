import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './assets/css/portal.css'
import './assets/plugins/popper.min.js'
import './assets/plugins/bootstrap/js/bootstrap.min.js'

let artisan = 'http://127.0.0.1:8000';
let localhost = 'http://localhost/doctor/backend';

let domain =  window.location.hostname == 'localhost' 
  ? artisan
  : window.location.origin+'/backend';

const Axios = axios.create({
  baseURL: `${domain}/api/`,
});
window.axios = Axios

createApp(App).use(store).use(router).mount('#app')
