import { createStore } from 'vuex'
import auth from './auth'
import appointment from './appointment';
import prescription from './prescription';

export default createStore({
  modules: {
    auth,
    appointment,
    prescription,
  }
})
